.box {
  display: flex !important;
  text-align: center;
  justify-content: center !important;
  align-items: center;
}

.inputs {
  margin-left: auto;
}

.input {
  /* padding: 8px; */
  /* padding-top: 20px; */
  /* padding-bottom: 0px; */
}
.width50 {
  width: 50% !important;
  display: inline-block;
}
.widthcustom {
  width: 100px;
  display: inline-block;
}
.datePickerset:first-child div{
  display: flex;
  /* flex-direction: column; */
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

.rowwidth {
  width: calc(100% - 150px);
}
